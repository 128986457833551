exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-antocianos-jsx": () => import("./../../../src/pages/antocianos.jsx" /* webpackChunkName: "component---src-pages-antocianos-jsx" */),
  "component---src-pages-canal-etico-jsx": () => import("./../../../src/pages/canal-etico.jsx" /* webpackChunkName: "component---src-pages-canal-etico-jsx" */),
  "component---src-pages-concentrado-de-vino-jsx": () => import("./../../../src/pages/concentrado-de-vino.jsx" /* webpackChunkName: "component---src-pages-concentrado-de-vino-jsx" */),
  "component---src-pages-contacto-jsx": () => import("./../../../src/pages/contacto.jsx" /* webpackChunkName: "component---src-pages-contacto-jsx" */),
  "component---src-pages-donde-estamos-jsx": () => import("./../../../src/pages/donde-estamos.jsx" /* webpackChunkName: "component---src-pages-donde-estamos-jsx" */),
  "component---src-pages-e-163-jsx": () => import("./../../../src/pages/e-163.jsx" /* webpackChunkName: "component---src-pages-e-163-jsx" */),
  "component---src-pages-en-about-us-facilities-jsx": () => import("./../../../src/pages/en/about-us/facilities.jsx" /* webpackChunkName: "component---src-pages-en-about-us-facilities-jsx" */),
  "component---src-pages-en-about-us-history-jsx": () => import("./../../../src/pages/en/about-us/history.jsx" /* webpackChunkName: "component---src-pages-en-about-us-history-jsx" */),
  "component---src-pages-en-about-us-values-jsx": () => import("./../../../src/pages/en/about-us/values.jsx" /* webpackChunkName: "component---src-pages-en-about-us-values-jsx" */),
  "component---src-pages-en-canal-etico-jsx": () => import("./../../../src/pages/en/canal-etico.jsx" /* webpackChunkName: "component---src-pages-en-canal-etico-jsx" */),
  "component---src-pages-en-contact-us-jsx": () => import("./../../../src/pages/en/contact-us.jsx" /* webpackChunkName: "component---src-pages-en-contact-us-jsx" */),
  "component---src-pages-en-enocyanin-jsx": () => import("./../../../src/pages/en/enocyanin.jsx" /* webpackChunkName: "component---src-pages-en-enocyanin-jsx" */),
  "component---src-pages-en-grape-anthocyanins-jsx": () => import("./../../../src/pages/en/grape-anthocyanins.jsx" /* webpackChunkName: "component---src-pages-en-grape-anthocyanins-jsx" */),
  "component---src-pages-en-grape-seed-extract-suppliers-jsx": () => import("./../../../src/pages/en/grape-seed-extract-suppliers.jsx" /* webpackChunkName: "component---src-pages-en-grape-seed-extract-suppliers-jsx" */),
  "component---src-pages-en-grape-skin-extracts-jsx": () => import("./../../../src/pages/en/grape-skin-extracts.jsx" /* webpackChunkName: "component---src-pages-en-grape-skin-extracts-jsx" */),
  "component---src-pages-en-grape-tannins-jsx": () => import("./../../../src/pages/en/grape-tannins.jsx" /* webpackChunkName: "component---src-pages-en-grape-tannins-jsx" */),
  "component---src-pages-en-index-jsx": () => import("./../../../src/pages/en/index.jsx" /* webpackChunkName: "component---src-pages-en-index-jsx" */),
  "component---src-pages-en-natural-food-colors-manufacturers-jsx": () => import("./../../../src/pages/en/natural-food-colors-manufacturers.jsx" /* webpackChunkName: "component---src-pages-en-natural-food-colors-manufacturers-jsx" */),
  "component---src-pages-en-news-jsx": () => import("./../../../src/pages/en/news.jsx" /* webpackChunkName: "component---src-pages-en-news-jsx" */),
  "component---src-pages-en-polyphenols-suppliers-jsx": () => import("./../../../src/pages/en/polyphenols-suppliers.jsx" /* webpackChunkName: "component---src-pages-en-polyphenols-suppliers-jsx" */),
  "component---src-pages-en-proanthocyanidins-suppliers-jsx": () => import("./../../../src/pages/en/proanthocyanidins-suppliers.jsx" /* webpackChunkName: "component---src-pages-en-proanthocyanidins-suppliers-jsx" */),
  "component---src-pages-en-product-alcohols-jsx": () => import("./../../../src/pages/en/product/alcohols.jsx" /* webpackChunkName: "component---src-pages-en-product-alcohols-jsx" */),
  "component---src-pages-en-product-grape-polyphenols-animal-feed-jsx": () => import("./../../../src/pages/en/product/grape-polyphenols-animal-feed.jsx" /* webpackChunkName: "component---src-pages-en-product-grape-polyphenols-animal-feed-jsx" */),
  "component---src-pages-en-product-grape-seed-extract-jsx": () => import("./../../../src/pages/en/product/grape-seed-extract.jsx" /* webpackChunkName: "component---src-pages-en-product-grape-seed-extract-jsx" */),
  "component---src-pages-en-product-grape-seed-jsx": () => import("./../../../src/pages/en/product/grape-seed.jsx" /* webpackChunkName: "component---src-pages-en-product-grape-seed-jsx" */),
  "component---src-pages-en-product-grape-skin-extracts-jsx": () => import("./../../../src/pages/en/product/grape-skin-extracts.jsx" /* webpackChunkName: "component---src-pages-en-product-grape-skin-extracts-jsx" */),
  "component---src-pages-en-product-grape-tannins-jsx": () => import("./../../../src/pages/en/product/grape-tannins.jsx" /* webpackChunkName: "component---src-pages-en-product-grape-tannins-jsx" */),
  "component---src-pages-en-product-wine-extracts-jsx": () => import("./../../../src/pages/en/product/wine-extracts.jsx" /* webpackChunkName: "component---src-pages-en-product-wine-extracts-jsx" */),
  "component---src-pages-en-red-wine-concentrate-jsx": () => import("./../../../src/pages/en/red-wine-concentrate.jsx" /* webpackChunkName: "component---src-pages-en-red-wine-concentrate-jsx" */),
  "component---src-pages-en-red-wine-extract-powder-jsx": () => import("./../../../src/pages/en/red-wine-extract-powder.jsx" /* webpackChunkName: "component---src-pages-en-red-wine-extract-powder-jsx" */),
  "component---src-pages-en-red-wine-extracts-jsx": () => import("./../../../src/pages/en/red-wine-extracts.jsx" /* webpackChunkName: "component---src-pages-en-red-wine-extracts-jsx" */),
  "component---src-pages-en-sustainability-jsx": () => import("./../../../src/pages/en/sustainability.jsx" /* webpackChunkName: "component---src-pages-en-sustainability-jsx" */),
  "component---src-pages-en-where-we-are-jsx": () => import("./../../../src/pages/en/where-we-are.jsx" /* webpackChunkName: "component---src-pages-en-where-we-are-jsx" */),
  "component---src-pages-en-white-wine-concentrate-jsx": () => import("./../../../src/pages/en/white-wine-concentrate.jsx" /* webpackChunkName: "component---src-pages-en-white-wine-concentrate-jsx" */),
  "component---src-pages-en-wine-concentrate-jsx": () => import("./../../../src/pages/en/wine-concentrate.jsx" /* webpackChunkName: "component---src-pages-en-wine-concentrate-jsx" */),
  "component---src-pages-en-wine-extracts-jsx": () => import("./../../../src/pages/en/wine-extracts.jsx" /* webpackChunkName: "component---src-pages-en-wine-extracts-jsx" */),
  "component---src-pages-enocianina-jsx": () => import("./../../../src/pages/enocianina.jsx" /* webpackChunkName: "component---src-pages-enocianina-jsx" */),
  "component---src-pages-extracto-de-piel-de-uva-jsx": () => import("./../../../src/pages/extracto-de-piel-de-uva.jsx" /* webpackChunkName: "component---src-pages-extracto-de-piel-de-uva-jsx" */),
  "component---src-pages-extracto-de-semilla-de-uva-jsx": () => import("./../../../src/pages/extracto-de-semilla-de-uva.jsx" /* webpackChunkName: "component---src-pages-extracto-de-semilla-de-uva-jsx" */),
  "component---src-pages-extracto-de-uva-jsx": () => import("./../../../src/pages/extracto-de-uva.jsx" /* webpackChunkName: "component---src-pages-extracto-de-uva-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-noticias-jsx": () => import("./../../../src/pages/noticias.jsx" /* webpackChunkName: "component---src-pages-noticias-jsx" */),
  "component---src-pages-opc-uva-jsx": () => import("./../../../src/pages/opc-uva.jsx" /* webpackChunkName: "component---src-pages-opc-uva-jsx" */),
  "component---src-pages-polifenoles-de-uva-jsx": () => import("./../../../src/pages/polifenoles-de-uva.jsx" /* webpackChunkName: "component---src-pages-polifenoles-de-uva-jsx" */),
  "component---src-pages-producto-alcoholes-jsx": () => import("./../../../src/pages/producto/alcoholes.jsx" /* webpackChunkName: "component---src-pages-producto-alcoholes-jsx" */),
  "component---src-pages-producto-extracto-de-semilla-de-uva-jsx": () => import("./../../../src/pages/producto/extracto-de-semilla-de-uva.jsx" /* webpackChunkName: "component---src-pages-producto-extracto-de-semilla-de-uva-jsx" */),
  "component---src-pages-producto-extracto-nutricion-animal-jsx": () => import("./../../../src/pages/producto/extracto-nutricion-animal.jsx" /* webpackChunkName: "component---src-pages-producto-extracto-nutricion-animal-jsx" */),
  "component---src-pages-producto-extracto-piel-uva-jsx": () => import("./../../../src/pages/producto/extracto-piel-uva.jsx" /* webpackChunkName: "component---src-pages-producto-extracto-piel-uva-jsx" */),
  "component---src-pages-producto-extracto-vino-jsx": () => import("./../../../src/pages/producto/extracto-vino.jsx" /* webpackChunkName: "component---src-pages-producto-extracto-vino-jsx" */),
  "component---src-pages-producto-pepita-de-uva-jsx": () => import("./../../../src/pages/producto/pepita-de-uva.jsx" /* webpackChunkName: "component---src-pages-producto-pepita-de-uva-jsx" */),
  "component---src-pages-producto-taninos-uva-jsx": () => import("./../../../src/pages/producto/taninos-uva.jsx" /* webpackChunkName: "component---src-pages-producto-taninos-uva-jsx" */),
  "component---src-pages-productos-derivados-de-uva-jsx": () => import("./../../../src/pages/productos-derivados-de-uva.jsx" /* webpackChunkName: "component---src-pages-productos-derivados-de-uva-jsx" */),
  "component---src-pages-semilla-uva-jsx": () => import("./../../../src/pages/semilla-uva.jsx" /* webpackChunkName: "component---src-pages-semilla-uva-jsx" */),
  "component---src-pages-sobre-nosotros-instalaciones-jsx": () => import("./../../../src/pages/sobre-nosotros/instalaciones.jsx" /* webpackChunkName: "component---src-pages-sobre-nosotros-instalaciones-jsx" */),
  "component---src-pages-sobre-nosotros-nuestra-historia-jsx": () => import("./../../../src/pages/sobre-nosotros/nuestra-historia.jsx" /* webpackChunkName: "component---src-pages-sobre-nosotros-nuestra-historia-jsx" */),
  "component---src-pages-sobre-nosotros-valores-jsx": () => import("./../../../src/pages/sobre-nosotros/valores.jsx" /* webpackChunkName: "component---src-pages-sobre-nosotros-valores-jsx" */),
  "component---src-pages-sostenibilidad-jsx": () => import("./../../../src/pages/sostenibilidad.jsx" /* webpackChunkName: "component---src-pages-sostenibilidad-jsx" */),
  "component---src-pages-taninos-uva-jsx": () => import("./../../../src/pages/taninos-uva.jsx" /* webpackChunkName: "component---src-pages-taninos-uva-jsx" */)
}

